import BaseInput from '@/components/atoms/_BaseInput.vue'
import BaseButton from '@/components/atoms/base-button/index.vue'

import { mapGetters, mapActions } from 'vuex'
import { validationMixin } from 'vuelidate'
import { email, required } from 'vuelidate/lib/validators'
import { get } from 'lodash'

export default {
  name: 'Recovery',
  mixin: [validationMixin],
  data () {
    return {
      email: '',
      loading: false,
      isError: false,
      step: 1,
      validationErrors: {}
    }
  },
  components: {
    BaseInput,
    BaseButton
  },
  validations: {
    email: {
      required,
      email
    }
  },
  computed: {
    ...mapGetters({
      videoPopupId: 'packages/videoPopup'
    }),
    emailErrors () {
      const error = []
      if (!this.$v.email.$dirty) {
        return error
      }
      if (!this.$v.email.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.email.email) {
        error.push(this.$t('validationEmail'))
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row)
        })
      }
      return error
    }
  },
  created () {
    this.resetForm()
  },
  methods: {
    ...mapActions({
      requestMagicRecovery: 'auth/requestMagicRecovery'
    }),
    resetForm () {
      this.isError = false
      this.email = ''
      this.step = 1
      this.$v.$reset()
    },
    async recovery () {
      this.$v.$touch()
      if (this.loading || this.$v.$invalid) return
      this.loading = true
      this.isError = false
      try {
        await this.requestMagicRecovery(this.email)
        this.step = 2
      } catch (e) {
        const error = get(e, 'response.data', {})
        if (error.errorType === 'existence') {
          this.isError = true
        }
      } finally {
        this.loading = false
      }
    }
  },
  destroyed () {
    this.resetForm()
  }
}
